export default [
  {
    title: "- Студент, имеющий двух родителей прикрепляет:",
    list: [
      { point: "2 справки о доходах родителей за последние 3 месяца;" },
      { point: "справка о составе семьи/прописка;" },
      {
        point: "справка личных доходов студента за последние 3 месяца.",
      },
    ],
  },
  {
    title: "- Студент, имеющий одного родителя прикрепляет:",
    list: [
      { point: "1 справку о доходах родителя за последние 3 месяца;" },
      { point: "справка о составе семьи/прописка;" },
      {
        point: "справка личных доходов студента за последние 3 месяца.",
      },
    ],
  },
  {
    title:
      "- Студент (до 23 лет), не имеющий родителей, но имеет попечителя/усыновителя прикрепляет:",
    list: [
      {
        point:
          "справка о доходах попечителя/усыновителя за последние 3 месяца;",
      },
      {
        point: "справка о попечительстве/усыновлении;",
      },
      {
        point: "справка о составе семьи/прописка;",
      },
      {
        point: "справка личных доходов студента за последние 3 месяца;",
      },
      {
        point:
          "справка, подтверждающая статус лица из числа детей-сирот и детей, оставшихся без попечения родителей.",
      },
    ],
  },
  {
    title: "- Студент (старше 23 лет), не имеющий родителей, прикрепляет:",
    list: [
      {
        point: "справка о составе семьи/прописка;",
      },
      {
        point: "справка личных доходов студента за последние 3 месяца.",
      },
    ],
  },
  {
    title: "- Студент, являющийся членом семьи участника СВО прикрепляет:",
    list: [
      {
        point: "документ, подтверждающий статус члена семьи участника СВО;",
      },
      {
        point: "документ, подтверждающий статус участника СВО.",
      },
      {
        type: "info",
        point:
          'Документы необходимо добавить в поле "Подтверждение категории социально нуждающегося”.',
      },
    ],
  },
  // {
  //   bold: true,
  //   title:
  //     "ВСЕМ участникам, имеющим постоянную прописку НЕ на территории Республики Татарстан - необходимо приложить временную регистрацию в РТ.",
  // },
];
